import { cn } from '@finn/ui-utils';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoContent from './PromoContent';
import PromoImage from './PromoImage';

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoDesktop: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const containerRef = useRef();
  const { enterCount } = useInViewport(
    containerRef,
    {
      threshold: 0.4,
    },
    { disconnectOnLeave: true },
    {}
  );

  const visible = enterCount >= 1;

  return (
    <div
      className="mx-auto my-0 w-[calc(1260px+180px)] max-w-[2100px] overflow-hidden"
      ref={containerRef}
    >
      <div
        className="flex w-full justify-end overflow-hidden rounded"
        style={{ backgroundColor: data.color_hex }}
      >
        <div className="min-h-[832px] w-full max-w-full sm:flex sm:min-h-[448px] md:max-h-[860px] md:min-h-[760px]">
          <div
            className={cn(
              'flex min-h-[400px] w-1/2 translate-y-[50px] flex-col p-24 opacity-0 transition-all',
              {
                'text-black': data.dark_text,
                'text-white': !data.dark_text,
                'order-2 pl-24': data.reverse,
                'translate-y-0 opacity-100': visible,
              }
            )}
          >
            <PromoContent data={data} slug={slug} />
          </div>

          <div
            className={cn(
              'order-2 h-full w-1/2 overflow-hidden bg-black object-cover [&_picture]:h-full',
              {
                'order-1': data.reverse,
              }
            )}
          >
            <PromoImage
              src={data.image?.url}
              altText={data?.image_alt_text || data.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoDesktop;
