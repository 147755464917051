import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoDesktop from './PromoDesktop';
import PromoMobile from './PromoMobile';

type Props = {
  data: PromoCosmicMetadata;
  id?: string;
  slug?: string;
};

const Promo = ({ data, id = '', slug = '' }: Props) => {
  return (
    <div id={id || data.id} data-testid="promo">
      <div className="hidden md:block">
        <PromoDesktop data={data} slug={slug || id} />
      </div>
      <div className="block md:hidden">
        <PromoMobile data={data} slug={slug || id} />
      </div>
    </div>
  );
};

export default Promo;
