import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl } from '@finn/ui-utils';
import React from 'react';

type Props = {
  src: string;
  altText?: string;
};

const PromoImage = ({ src, altText = '' }: Props) => {
  return (
    <Image
      variant="responsive"
      className="w-full object-cover"
      lgImage={getCloudinaryImgUrl(src, {
        w: 625,
        dpr: 2,
      })}
      mdImage={getCloudinaryImgUrl(src, {
        w: 372,
        dpr: 2,
      })}
      smImage={getCloudinaryImgUrl(src, {
        w: 375,
        dpr: 2,
      })}
      alt={altText}
    />
  );
};

export default PromoImage;
